<script setup lang="ts">
const modalStore = useModalStore();
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  width: {
    type: Number,
    default: 500,
  },
  isLoading: Boolean,
});
</script>

<template>
  <el-dialog
    class="base-modal"
    v-model="modalStore.isModalOpen"
    :width="props.width"
    :title="props.title"
    @closed="modalStore.closeModal"
  >
    <BaseLoader class="absolute top-1/2 left-1/2" v-if="props.isLoading" />
    <slot v-else></slot>
    <template #footer>
      <div class="base-modal__footer flex justify-end items-center gap-2">
        <slot name="footer"></slot>
      </div>
    </template>
  </el-dialog>
</template>
